global.config = {
  google_api_key: process.env.VUE_APP_GOOGLE_API_KEY,
  googleGeocodeParams: {
    components: "country:it"
  },
  baseUrl: "",
  proximityDefaultDistance: "10000.0",
  showSectors: false,
  hashNotReload: ["/cart/", "/payment"],
  filterEmptyCategory: true,
  dayWordsSaved: 30,
  version: process.env.VUE_APP_VERSION,
  minProfileLevel: 1,
  registration: true,
  checkVersionInterval: 1000 * 60 * 10, //ogni 10 minuti
  reloadAfterMillis: 1000 * 60 * 30, //dopo 30 minuti di inattività restarta la app
  domainWhitelist: [
    "zona.eu",
    "test.zona.eu",
    "www.zona.eu",
    "test-zona.digitelematica.org",
    "dev-zona.digitelematica.org",
    "https://www.zona.eu",
    "localhost"
  ],
  showInPopupCode: ["msg.cart.availability.errors"],
  dialogs: [],
  pushNotificationEnabled: true,
  urlScheme: "ebsnsidal://",
  deeplinks: {
    "/profile/orders": "/profile/orders",
    "/profile/orders/:orderId": "/profile/orders/:orderId",
    "/checkout": "/checkout",
    "/search": "/search",
    "/product/:slug": "/product/:slug",
    "/category/*": "/category/*",
    "/page/:slug": "/page/:slug",
    "/recover-password-confirm": "/recover-password-confirm",
    "/registration/reset-password-confirm":
      "/registration/reset-password-confirm",
    "/reset-password-request": "/reset-password-request",
    "/reset-password-sent": "/reset-password-sent",
    "/reset-password": "/reset-password",
    "/reset-password-completed": "/reset-password-completed",
    "/p/volantini/": "/page/volantini/"
  },
  // filterProductClasses: [1, 2, 3, 4, 6],
  updateCart: false,
  dialogMaxWidth: 530,
  addToFavoritesWidth: 350,
  analyticsClass: "analyticsServiceGTM",
  cartItemInfos: ["size", "customWeight"],
  acceptAlternatives: false,
  productVoteMaxRating: 5,
  categoryTreeDepth: 3,
  // facebookClientId: "1250974205313975",
  // googleClientId:
  //   "1061314893204-tbicvnnsq74cjiarigmq3jiilqu56b0v.apps.googleusercontent.com",
  defaultPaymentTypeId: 12,
  socialLogo: "/logo/logo_mobile.png",
  defaultStoreName: "Catalogo Globale",
  recaptchaTokenEnabled: true,
  recaptchaEnabledEndpoints: [
    // // "/ebsn/api/registration/user_detail",
    "/ebsn/api/registration/update_user_detail",
    "/ebsn/api/auth/login",
    "/ebsn/api/checkout/buy",
    "/ebsn/api/sidal-registration/update_contact_preference",
    "/ebsn/api/sidal-registration/update_user_detail",
    "/ebsn/api/registration/password_reset",
    "/ebsn/api/sidal-registration/register_user",
    "/ebsn/api/registration/update_password",
    "/ebsn/api/sidal-registration/check_customer_code"
  ],
  categoryPrefix: ""
};
